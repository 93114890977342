<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <FormButtonGroup align="end" spaced>
          <FormButton
            text="Nova página estática"
            type="primario"
            @click="onNew"
          />
        </FormButtonGroup>
      </template>
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        sortBy="titulo"
        :sortDesc="false"
        @click="onClick"
        ref="datatable"
        search
      >
        <template v-slot:slug="{ col }">
          /{{ col.length > 50 ? `${col.substr(0, 47)}...` : col }}
        </template>
        <template v-slot:idconteudo="{ row }">
          <FormButtonMenu
            icon
            :options="tableOptions"
            :loading="loadingRow[row.idconteudoestatico]"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import Panel from '@/components/Panel'
import FormButton from '@/components/form/FormButton'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import MarketingModules from '@/components/MarketingModules'
import { mapGetters } from 'vuex'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import { PAGINA_ESTATICA_REMOVE } from '@/store/actions/paginaEstatica'

export default {
  name: 'PaginaEstaticaTable',
  components: {
    DataTable,
    Panel,
    FormButton,
    FormButtonMenu,
    FormButtonGroup,
    MarketingModules
  },
  data: () => ({
    headers: [
      { text: 'Nome da página', value: 'titulo' },
      { text: 'Slug', value: 'slug' },
      { text: 'Conteúdo', value: 'conteudo' },
      { text: 'Opções', value: 'idconteudo', clickable: false, align: 'center' }
    ],
    tableOptions: [{ text: 'Deletar', value: 'delete' }],
    loadingRow: {},
    modulosForm: [
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP,
      ModulesEnum.PORTFOLIO_WEB,
      ModulesEnum.PAINEL_CLIENTE
    ]
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    endpoint() {
      return `/api/v4/pagina-estatica/datatable/${this.getFornecedorId}`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  created() {},
  methods: {
    onClick(item) {
      this.$router.push(`pagina-estatica/${item.idconteudoestatico}`)
    },
    onNew() {
      this.$router.push('pagina-estatica/novo')
    },
    onClose() {
      this.$refs.datatable.reload()
      this.loadingRow = {}
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'delete':
          return this.onDelete(item)
      }
    },
    onDelete(item) {
      const options = {
        text: 'Essa ação é irreversível.',
        title: `Você confirma a exclusão da página estática '${item.titulo}'?`
      }
      this.confirmSwal(options, () => this.delete(item))
    },
    delete(item) {
      this.loadingRow = {
        ...this.loadingRow,
        [item.idconteudoestatico]: true
      }
      this.$store
        .dispatch(PAGINA_ESTATICA_REMOVE, item.idconteudoestatico)
        .then(() => {
          this.$vueOnToast.pop(
            'success',
            'Página estática removida com sucesso'
          )
          this.$refs.datatable.reload()
          this.loadingRow = {
            ...this.loadingRow,
            [item.idconteudoestatico]: false
          }
        })
        .catch(() => {
          this.loadingRow = {
            ...this.loadingRow,
            [item.idconteudoestatico]: false
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
